<!--
 * @Author: your name
 * @Date: 2021-04-20 21:23:20
 * @LastEditTime: 2021-05-11 17:44:38
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \one\src\components\filtermenu\index.vue
-->
<template>
  <div class="allmsgclass">
    <van-popup
      v-model="showshai"
      closeable
      close-icon-position="top-left"
      position="bottom"
      :style="{ height: '200%' }"
    >
      <div class="biaot">筛选</div>
      <div class="msgclassse">
        <div>
          <div class="jycla">
            招聘岗位<span style="margin-left: 0.1rem; color: #ff5f19">*</span>
          </div>
          <div class="paixu paixu22">
            <div @click="gangwei" class="paixu1">选择招聘岗位</div>
            <div
              @click="arr8 ? (arr8 = '') : ''"
              :class="arr8 == '' ? '' : 'paixu8'"
            >
              {{ arr8 }}
              <div
                :class="arr8 == '' ? 'closeclass closeclass2' : 'closeclass'"
              >
                <van-icon size=".2rem" name="cross" />
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="jycla">
            招聘区域<span style="margin-left: 0.1rem; color: #ff5f19">*</span>
          </div>
          <div class="paixu paixu22">
            <div @click="quyu" class="paixu1">选择招聘区域</div>
            <div
              @click="arr9 ? (arr9 = '') : ''"
              :class="arr9 == '' ? '' : 'paixu8'"
            >
              {{ arr9 }}
              <div
                :class="arr9 == '' ? 'closeclass closeclass2' : 'closeclass'"
              >
                <van-icon size=".2rem" name="cross" />
              </div>
            </div>
          </div>
        </div>
        <div v-for="(item, index) in selearray" v-bind:key="index">
          <div class="jycla">{{ item.name }}</div>
          <div
            :class="yixiangarray.length % 3 == 2 ? 'paixu paixu22' : 'paixu'"
          >
            <div
              @click="paixumeth(itemes, indexes, index)"
              :class="
                index == 0
                  ? arr1 == itemes.value
                    ? 'paixu2'
                    : 'paixu1'
                  : index == 1
                  ? arr2 == itemes.value
                    ? 'paixu2'
                    : 'paixu1'
                  : index == 2
                  ? arr3 == itemes.value
                    ? 'paixu2'
                    : 'paixu1'
                  : index == 3
                  ? arr4 == itemes.value
                    ? 'paixu2'
                    : 'paixu1'
                  : index == 4
                  ? arr5 == itemes.value
                    ? 'paixu2'
                    : 'paixu1'
                  : index == 5
                  ? arr6 == itemes.value
                    ? 'paixu2'
                    : 'paixu1'
                  : index == 6
                  ? arr10 == itemes.value
                    ? 'paixu2'
                    : 'paixu1'
                  : ''
              "
              v-for="(itemes, indexes) in item.msg"
              v-bind:key="indexes"
            >
              {{ itemes.name }}
            </div>
          </div>
        </div>

        <div>
          <div class="jycla">意向行业</div>
          <div class="paixu paixu22">
            <div @click="yixiangmeth" class="paixu1">选择意向行业</div>
            <div
              @click="arr7 ? (arr7 = '') : ''"
              :class="arr7 == '' ? '' : 'paixu2'"
            >
              {{ arr7 }}
              <div
                :class="arr7 == '' ? 'closeclass closeclass2' : 'closeclass'"
              >
                <van-icon size=".2rem" name="cross" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="nomsgclass"></div>
      <div class="buttonclass">
        <button @click="clearmeth" class="bu1">清除</button>
        <button @click="clickok" class="bu2">确认</button>
      </div>
      <jobsearch
        @jobsearchreturn="jobsearchreturn"
        ref="jobsearchid"
      ></jobsearch>
      <addresses
        :msg="2"
        @addressreturn="addressreturn"
        ref="addressid"
      ></addresses>
    </van-popup>
    <industry ref="industryid"></industry>
    <yixiang ref="yixiangid" @confirm="confirmyixiang"></yixiang>
  </div>
</template>
<script>
import jobsearch from "../../components/jobsearch";
import addresses from "../../components/address";
import industry from "../../components/industry";
import yixiang from "../../components/yixiang/index.vue";
import tomsgcity from "../../../util/citytransit";
export default {
  components: {
    jobsearch,
    addresses,
    industry,
    yixiang,
  },
  props: {
    searray: Array,
  },
  data() {
    return {
      showshai: false,
      arr1: "", //经验要求
      arr2: "", //学历要求
      arr3: "", //薪资待遇
      arr4: "", //年龄
      arr5: "", //政治面貌
      arr6: "", //性别
      arr7: "", //意向行业
      arr8: "", //求职岗位
      arr9: "", //求职区域
      arr10: "", //是否实名
      yixiangarray: [], //意向行业
      indexsele: [], //选择下标
      selearray: [
        {
          name: "经验要求",
          msg: [
            {
              name: "全部",
              value: "",
            },
            {
              name: "1年以下",
              value: "0-1",
            },
            {
              name: "1-2年",
              value: "1-2",
            },
            {
              name: "2-3年",
              value: "2-3",
            },
            {
              name: "3-5年",
              value: "3-5",
            },
            {
              name: "5-10年",
              value: "5-10",
            },
            {
              name: "10-99年",
              value: "10-99",
            },
          ],
        },
        {
          name: "学历要求",
          msg: [
            {
              name: "全部",
              value: "",
            },
            {
              name: "中专及以下",
              value: "高中中专及以下",
            },
            {
              name: "大专",
              value: "大专",
            },
            {
              name: "本科",
              value: "本科",
            },
            {
              name: "硕士",
              value: "硕士",
            },
            {
              name: "博士",
              value: "博士",
            },
          ],
        },
        {
          name: "薪资待遇",
          msg: [
            {
              name: "全部",
              value: "",
            },
            {
              name: "5k以下",
              value: "5000(元)以下",
            },
            {
              name: "5-10k",
              value: "5000-10000(元)",
            },
            {
              name: "10-15k",
              value: "10000-15000(元)",
            },
            {
              name: "15-20k",
              value: "15000-20000(元)",
            },
            {
              name: "20k以上",
              value: "20000(元)以上",
            },
          ],
        },
        {
          name: "年龄",
          msg: [
            {
              name: "全部",
              value: "",
            },
            {
              name: "16-20(岁)",
              value: "16-20",
            },
            {
              name: "20-24(岁)",
              value: "20-24",
            },
            {
              name: "24-27(岁)",
              value: "24-27",
            },
            {
              name: "27-30(岁)",
              value: "27-30",
            },
            {
              name: "30-35(岁)",
              value: "30-35",
            },
            {
              name: "35岁以上",
              value: "35-100",
            },
          ],
        },
        {
          name: "政治面貌",
          msg: [
            {
              name: "全部",
              value: "",
            },
            {
              name: "群众",
              value: "群众",
            },
            {
              name: "党员",
              value: "党员",
            },
            {
              name: "民主党派",
              value: "民主党派",
            },
          ],
        },
        {
          name: "性别",
          msg: [
            {
              name: "全部",
              value: "",
            },
            {
              name: "男",
              value: "男",
            },
            {
              name: "女",
              value: "女",
            },
          ],
        },
        {
          name: "是否实名",
          msg: [
            {
              name: "全部",
              value: "",
            },
            {
              name: "未实名",
              value: "1",
            },
            {
              name: "已实名",
              value: "2",
            },
          ],
        },
      ],
    };
  },
  watch: {
    arr8(newmsg) {
      if (newmsg == "" && this.arr9 == "") {
        this.clearmeth();
      }
    },
    arr9(newmsg) {
      if (this.arr8 == "" && newmsg == "") {
        this.clearmeth();
      }
    },
    searray(newarray) {
      this.arr1 = newarray[0];
      this.arr2 = newarray[1];
      this.arr3 = newarray[2];
      this.arr4 = newarray[3];
      this.arr5 = newarray[4];
      this.arr6 = newarray[5];
      this.arr7 = newarray[6];
      this.arr8 = newarray[7]; //求职岗位
      this.arr9 = newarray[8]; //求职区域
      this.arr10 = newarray[9]; //是否实名
    },
  },
  mounted() {
    this.getaddressmeth();
  },
  methods: {
    getaddressmeth() {
      if (!localStorage.getItem("userId")) return;
      //获取注册时填写的地址
      this.$http
        .post("/firm/v1/userinfo/userinfo", {
          reqType: "profile",
          id: localStorage.getItem("userId"),
        })
        .then((res) => {
          let msg = JSON.parse(res.data);
          setTimeout(() => {
            if (!this.arr9) {
              if (Number(msg.data.province)) {
                let addr = tomsgcity.tomsgcity(
                  msg.data.province +
                    "/" +
                    msg.data.city +
                    "/" +
                    msg.data.district
                );
                this.arr9 = addr.split("/")[0] + "/" + addr.split("/")[1];
              } else {
                this.arr9 = msg.data.province + "/" + msg.data.city;
              }
            }
          }, 0);
        });
    },
    //是否符合条件
    iscanup() {
      if (!this.arr8) {
        this.$toast("请选择招聘岗位");
        return false;
      }
      if (!this.arr9) {
        this.$toast("请选择招聘区域");
        return false;
      }
      return true;
    },
    yixiangmeth() {
      this.$refs.yixiangid.showmeth();
    },
    confirmyixiang(e) {
      this.arr7 = e;
    },
    gangwei() {
      this.$refs.jobsearchid.jobsearchmeth("", "");
    },
    quyu() {
      this.$refs.addressid.addressmeth("2", "");
    },
    //求职区域返回的值
    addressreturn(msg, index) {
      this.arr9 = msg[0].name + "/" + msg[1].name;
    },
    //求职岗位返回的值
    jobsearchreturn(msg, index) {
      this.arr8 = msg[0] + "/" + msg[1] + "/" + msg[2];
    },
    showfilter() {
      this.showshai = true;
    },
    //点击确认
    clickok() {
      if (!this.arr8 && !this.arr9) {
      } else if (!this.iscanup()) {
        return;
      }
      sessionStorage.removeItem("sub_keepAlive_shaimsg");
      this.showshai = false;
      this.$emit("shaimeth", [
        this.arr1,
        this.arr2,
        this.arr3,
        this.arr4,
        this.arr5,
        this.arr6,
        this.arr7,
        this.arr8, //求职岗位
        this.arr9, //求职区域
        this.arr10, //是否实名
      ]);
    },
    clearmeth() {
      this.arr1 = ""; //经验要求
      this.arr2 = ""; //学历要求
      this.arr3 = ""; //薪资待遇
      this.arr4 = ""; //薪资待遇
      this.arr5 = ""; //政治面貌
      this.arr6 = ""; //性别
      this.arr7 = ""; //意向行业
      this.arr8 = ""; //求职岗位
      this.arr9 = ""; //求职区域
      this.arr10 = ""; //是否实名
    },
    paixumeth(msg, indexes, index) {
      if (!this.iscanup()) {
        return;
      }
      if (index == 0) {
        this.arr1 = msg.value;
      } else if (index == 1) {
        this.arr2 = msg.value;
      } else if (index == 2) {
        this.arr3 = msg.value;
      } else if (index == 3) {
        this.arr4 = msg.value;
      } else if (index == 4) {
        this.arr5 = msg.value;
      } else if (index == 5) {
        this.arr6 = msg.value;
      } else if (index == 6) {
        this.arr10 = msg.value;
      }
    },
  },
};
</script>
<style scoped>
.paixu22::after {
  content: "";
  display: block;
  width: 2.09rem;
}
.msgclassse {
  margin: 0.35rem 0.32rem;
}

.allmsgclass >>> .van-popup__close-icon--top-left {
  top: 0.3rem;
}
.allmsgclass >>> .van-popup__close-icon {
  font-size: 0.46rem;
}
.bu1 {
  font-size: 0.3rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 0.42rem;
  background: #f7f7f7;
  border-radius: 0.16rem;
  border: none;
  padding: 0.27rem 0;
  width: 33%;
  text-align: center;
}
.bu2 {
  font-size: 0.3rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 0.42rem;
  border-radius: 0.16rem;
  border: none;
  background: linear-gradient(90deg, #ff4800 0%, #fc9e46 100%);
  padding: 0.27rem 0;
  text-align: center;
  width: 53%;
}
.bu1,
.bu2 {
  white-space: nowrap;
}
.buttonclass {
  position: fixed;
  bottom: 0;
  padding: 0.3rem 5% 0.18rem 5%;
  width: 90%;
  box-shadow: 0 -0.05rem 0.1rem rgb(243, 241, 241);
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
}
.biaot {
  text-align: center;
  font-size: 0.34rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  line-height: 0.48rem;
  margin-top: 0.3rem;
}
/* .msgclassse > :nth-child(2) > :nth-child(2) > :last-child {
  background-color: #ffffff;
  visibility: hidden;
} */
.allmsgclass >>> .van-popup__close-icon {
  color: #141f33;
}
.jycla {
  font-size: 0.38rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  line-height: 0.48rem;
}
.paixu {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 0.36rem;
  margin-bottom: 0.36rem;
}
.paixu .paixu1 {
  text-align: center;
  font-size: 0.3rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #666666;
  line-height: 0.42rem;
  background: #f7f7f7;
  border-radius: 0.08rem;
  padding: 0.21rem 0;
  margin-bottom: 0.27rem;
}
.paixu2 {
  text-align: center;
  font-size: 0.3rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ff5f19;
  line-height: 0.42rem;
  background: #ffffff;
  padding: 0.18rem 0;
  margin-bottom: 0.27rem;
  position: relative;
  border-radius: 0.08rem;
  border: 0.03rem solid #ff5f19;
}
.paixu .paixu1,
.paixu2 {
  width: 29%;
}
.paixu8 {
  width: auto;
  text-align: center;
  font-size: 0.3rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ff5f19;
  line-height: 0.42rem;
  background: #ffffff;
  padding: 0.18rem 0.3rem;
  margin-bottom: 0.27rem;
  position: relative;
  border-radius: 0.08rem;
  border: 0.03rem solid #ff5f19;
  margin-left: 6%;
}
.paixu22 {
  display: flex;
  justify-content: flex-start;
}
.closeclass {
  position: absolute;
  top: -0.1rem;
  right: 0;
}
.closeclass2 {
  visibility: hidden;
}
.nomsgclass {
  height: 1.5rem;
}
</style>