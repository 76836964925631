<template>
  <div>
    <van-popup v-model="show" round position="bottom"
      ><van-picker
        @cancel="show = false"
        @confirm="onConfirm"
        show-toolbar
        title="意向行业"
        :columns="allmsg"
    /></van-popup>
  </div>
</template>
<script>
export default {
  data() {
    return {
      show: false,
      allmsg:[]
    };
  },
  mounted() {
    //获取意向行业
    this.$http
      .post("/firm/v1/Common/industry", {
        reqType: "resume",
      })
      .then((res) => {
        let msg = JSON.parse(res.data).data;
        let msgobj = [];
        for (let i = 0; i < msg.length; i++) {
          msgobj[i] = {};
          msgobj[i]["text"] = msg[i].name;
          let newchild = [];
          if (msg[i].child) {
            for (let j = 0; j < msg[i].child.length; j++) {
              newchild.push({
                text: msg[i].child[j].name,
              });
              msgobj[i].children = newchild;
            }
          }
        }
        this.allmsg = msgobj;
      });
  },
  methods:{
      onConfirm(e){
          this.$emit("confirm",e[1]);
          this.show = false;
      },
      showmeth(){
          this.show = true;
      }
  }
};
</script>
<style scoped>
</style>