<!--
 * @Author: your name
 * @Date: 2021-04-28 11:16:13
 * @LastEditTime: 2021-04-30 10:41:48
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \one\src\components\jobsearch.vue
-->
<template>
  <div>
    <van-popup v-model="show" round position="bottom"
      ><van-picker
        @cancel="show = false"
        @confirm="onConfirm"
        show-toolbar
        title="求职岗位"
        :columns="allmsg"
    /></van-popup>
  </div>
</template>
<script>
export default {
  data() {
    return {
      allmsg: [],
      show: false,
    };
  },
  created() {
    //求职岗位
    this.getqiuzhi();
  },
  methods: {
    jobsearchmeth(msg, index) {
      this.index = index;
      this.show = true;
    },
    onConfirm(value) {
      this.show = false;
      this.$emit("jobsearchreturn", value, this.index);
    },
    //求职岗位
    getqiuzhi() {
      this.$http
        .post("/firm/v1/Position/positionList", {
          reqType: "majors_index",
        })
        .then((res) => {
          let msg = JSON.parse(res.data).data.positionList;
          let msgobj = [];
          for (let i = 0; i < msg.length; i++) {
            msgobj[i] = {};
            msgobj[i]["text"] = msg[i].position_name;
            let newchild = [];
            if (msg[i].children) {
              for (let j = 0; j < msg[i].children.length; j++) {
                newchild.push({
                  text: msg[i].children[j].position_name,
                });
                msgobj[i].children = newchild;
                if (msg[i].children[j].children) {
                  let newchild2 = [];
                  for (let y = 0; y < msg[i].children[j].children.length; y++) {
                    newchild2.push({
                      text:msg[i].children[j].children[y].position_name
                    })
                    msgobj[i].children[j].children = newchild2;
                  }
                }
              }
            }

           
          }
          this.allmsg = msgobj;
        });
    },
  },
};
</script>
<style scoped>
</style>